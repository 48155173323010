<template>
    <div class="expenses">
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div class="card card-signin my-5">
                        <div class="card-body">
                            <b-alert v-if="submittingExpenseError" show variant="danger">{{ submittingExpenseError }}</b-alert>
                            <b-alert v-if="submittingExpenseSuccessful" show variant="success">Success!</b-alert>
                            <div v-if="!isLoaded" class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <b-form v-if="isLoaded" @submit.prevent="expensesSubmit">
                                <b-form-group label="Date:" label-for="date">
                                    <b-form-input
                                            id="date"
                                            v-model="date"
                                            type="date"
                                            required>
                                    </b-form-input>
                                </b-form-group>
                                <b-form-group label="Sum:" label-for="sum">
                                    <b-input-group class="mt-3">
                                    <b-input-group-prepend is-text><b>€</b></b-input-group-prepend>
                                    <b-form-input
                                            id="sum"
                                            v-model="sum"
                                            type="number"
                                            min="0.00"
                                            step="0.01"
                                            required
                                            class="form-control currency"
                                            placeholder="0.00">
                                    </b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group label="Note:" label-for="note">
                                    <b-form-textarea
                                            id="note"
                                            v-model="note"
                                            type="text"
                                            row="3"
                                            max-rows="16"
                                            placeholder="Note for the purchase ...">
                                    </b-form-textarea>
                                </b-form-group>
                                <b-form-group label="Category:" label-for="category">
                                    <b-form-select
                                            id="category"
                                            v-model="category"
                                            :options="categories"
                                            type="text"
                                            required>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group label="Type:" label-for="type">
                                    <b-form-select
                                            id="type"
                                            v-model="type"
                                            :options="types"
                                            type="text">
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group label="Vendor:" label-for="vendor">
                                    <b-form-select
                                            id="vendor"
                                            v-model="vendor"
                                            :options="vendors"
                                            type="text">
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group label="Account:" label-for="account">
                                    <b-form-select
                                            id="account"
                                            v-model="account"
                                            :options="accounts"
                                            required
                                            type="text">
                                    </b-form-select>
                                </b-form-group>
                                        <b-button type="submit">
                                            <div v-if="!submittingExpense">Submit</div>
                                            <div v-if="submittingExpense" class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </b-button>

                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import post from '../utility';

    export default {
        name: "Expenses",
        data() {
            return {
                isLoaded: false,
                date: new Date().toISOString().slice(0, 10),
                sum: null,
                note: '',
                category: '',
                type: '',
                vendor: '',
                account: '',
                categories: [],
                types: [],
                allTypes: [],
                vendors: [],
                allVendors: [],
                accounts: [],
            }
        },
        computed: {
            ...mapState([
                'submittingExpense',
                'submittingExpenseError',
                'submittingExpenseSuccessful',
            ])
        },
        watch: {
            category: function() {
                this.categoryChanged();
            }
        },
        created() {
            this.updateAllTypes();
            this.updateAllVendors();
            this.updateCategories();
            this.updateAccounts();
        },
        methods: {
            ...mapActions([
                'submitExpense'
            ]),
            expensesSubmit() {
                let data = {
                    date: this.date,
                    sum: this.sum,
                    note: this.note,
                    category: this.category,
                    type: this.type,
                    vendor: this.vendor,
                    account: this.account,
                };
                this.submitExpense(data).then(() => {
                    this.date = new Date().toISOString().slice(0, 10);
                    this.sum = null;
                    this.note = '';
                    this.type = '';
                    this.vendor = '';
                    this.category = this.categories.length > 0 ? this.categories[0].value : '';
                    this.account = this.accounts.length > 0 ? this.accounts[0].value : '';
                    window.scrollTo(0, 0);
                }).catch(() => {
                    window.scrollTo(0, 0);
                });
            },
            categoryChanged() {
                this.updateTypes();
                this.updateVendors();
            },
            async updateCategories() {
                let data = {};
                post("getCategories", data).then(response => {
                    if (response.data.status !== 'error') {
                        this.categories = response.data.categories.map(category => {
                            return {
                                value: category,
                                text: category
                            };
                        });
                        if(this.categories.length > 0)
                            this.category = this.categories[0].value;
                        this.updateTypes();
                        this.updateVendors();
                        this.isLoaded = true;
                    }
                });
            },
            async updateTypes() {
                this.types = [];
                this.type = '';

                for(let i = 0; i < this.allTypes.length; i++)
                {
                    if(this.allTypes[i].parentCategories.includes(this.category))
                        this.types.push({
                            value: this.allTypes[i].type,
                            text: this.allTypes[i].type,
                        });
                }
                this.types.unshift({value: null, text: ''});
                this.type = this.types[0].value;
            },
            async updateAllTypes() {
                this.allTypes = [];
                let parameters = {
                };
                post("getTypes", parameters).then(response => {
                    if (response.data.status !== 'error') {
                        this.allTypes = response.data.types;
                        this.updateTypes();
                    }
                });
            },
            async updateVendors() {
                this.vendors = [];
                this.vendor = '';

                for(let i = 0; i < this.allVendors.length; i++)
                {
                    if(this.allVendors[i].parentCategories.includes(this.category))
                        this.vendors.push({
                            value: this.allVendors[i].vendor,
                            text: this.allVendors[i].vendor,
                        });
                }
                this.vendors.unshift({value: null, text: ''});
                this.vendor = this.vendors[0].value;
            },
            async updateAllVendors() {
                this.allVendors = [];
                let parameters = {
                };
                post("getVendors", parameters).then(response => {
                    if (response.data.status !== 'error') {
                        this.allVendors = response.data.vendors;
                        this.updateVendors();
                    }
                });
            },
            async updateAccounts() {
                let data = {};
                post("getAccounts", data).then(response => {
                    if (response.data.status !== 'error') {
                        this.accounts = response.data.accounts.map(account => {
                            return {
                                value: account,
                                text: account
                            };
                        });
                        if(this.accounts.length > 0)
                            this.account = this.accounts[0].value;
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>