<template>
    <Expenses />
</template>

<script>
    import Expenses from '../components/Expenses.vue';

    export default {
        components: {
            Expenses
        }
    }
</script>